<template>
  <div>
    <div><h1>Admin</h1></div>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <hr>
    <iframe
      style="width: 85.5%; height: 70vh;"
      src="https://dashboard.x9tech.com/dashboard/asi-smarttrak"
    />
  </div>
</template>

<script>
import {  BBreadcrumb, } from "bootstrap-vue";

export default {
  components: {
    BBreadcrumb,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: "Utility",
          href: '/admin/utility'
        },
        {
          text: "Admin Dashboard",
          active: true,
        },
      ],  
    }
  },
};

</script>

<style scoped>

</style>

